import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Link = makeShortcode("Link");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const AudioPlayer = makeShortcode("AudioPlayer");
const Box = makeShortcode("Box");
const List = makeShortcode("List");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "🤯-meaning",
      "style": {
        "position": "relative"
      }
    }}>{`🤯 Meaning`}<a parentName="h1" {...{
        "href": "#%F0%9F%A4%AF-meaning",
        "aria-label": "🤯 meaning permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <InfoBlock mdxType="InfoBlock">
      <p><em parentName="p">{`"When the truncheon may be used`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`in lieu of conversation,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`words retain their power.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Words offer the means to meaning and,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`for those who will listen,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`the enunciation of truth."`}</em><br parentName="p"></br>{`
`}<strong parentName="p">{`V for Vendetta`}</strong>{`  `}</p>
    </InfoBlock>
    <p>{`There's an internet-famous movie called `}<Link to="https://www.youtube.com/watch?v=ByY6j0qzOyM" mdxType="Link">{`Good Copy Bad Copy`}</Link>{`
made in 2007 about intellectual property, piracy, and the fight against copyright law. The central
thesis is that the document which most protects our liberty is no longer the Declaration of
Independence, or the Bill of Rights: it is the 50x50 matrix which is the decryption key for blu-ray disks.`}</p>
    <p>{`This is already outdated, but the premise is fascinating. The fight for liberty is not conducted
with natural language in the form of political rhetoric: it is `}<strong parentName="p">{`hashed out in technical protocols`}</strong>{`.
What matters most is not what you say, but what function(s) you can get your language to execute.`}</p>
    <p>{`This has been true since Phil Zimmerman published the source code for `}<Link to="https://books.google.co.za/books/about/PGP_Source_Code_and_Internals.html?id=xR4ZAQAAIAAJ&redir_esc=y" mdxType="Link">{`PGP as a book`}</Link>{`
in 1995 in order to use the First Amendment to get around something called the Arms Export Control Act.
Cryptography is so powerful, it is considered to be a munition by many governments. This is
because it executes speech freely, without the need for legal or political protection, and
therefore outside the realm of legal or political control.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Political rhetoric is not nearly as powerful as the `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{` you can get your language to `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}</p>
        <p>{`function(s), execute.`}</p>
      </Card>
    </Flash>
    <h2 {...{
      "id": "the-means",
      "style": {
        "position": "relative"
      }
    }}>{`The Means`}<a parentName="h2" {...{
        "href": "#the-means",
        "aria-label": "the means permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Now we have widely-deployed cryptography used to secure our ability to speak freely about what
we find to be valuable, without the need to trust corruptible institutions. It is a profound
shift in the evolution of our ability to make meaning, in the most general sense.`}</p>
    <p>{`The means by which we make such meaning are not owned by anyone, and the medium - which is both
a network and a scripting language - can be read in an essentially perfect way. That is, the
functions our language executes produce deterministic outputs which are globally agreed upon.
It gets even weirder, though. As Vitalik says in `}<a parentName="p" {...{
        "href": "/learn/module-1/understanding-ethereum"
      }}>{`Understanding Ethereum`}</a>{`;
contracts (code) and externally owned accounts (people) are both first class citizens on Ethereum.
Simon de la Rouviere describes it thus:`}</p>
    <blockquote>
      <p parentName="blockquote">{`“It’s doubtful that programs will develop the desire to connect for the sake of it (like we do),
unless we program them to do it. However, the benefits of knowing that a computation was
verifiably done is like inventing religion for programs `}{`[...]`}{` with verifiable computing
protocols, a program will know the minds of other programs. Except, unlike biology, where it is
imperfect, it will know exactly the state and processing capability. There’s no longer this idea
of servers of data and logic connected disparately through the network.”`}</p>
    </blockquote>
    <h2 {...{
      "id": "the-heart-of-it",
      "style": {
        "position": "relative"
      }
    }}>{`The Heart of It`}<a parentName="h2" {...{
        "href": "#the-heart-of-it",
        "aria-label": "the heart of it permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Aligner center mdxType="Aligner">
  <Button to='https://www.youtube.com/watch?v=3HcETLvJZ54' inline mdxType="Button">
    Rebuild Society on Meaning
  </Button>
    </Aligner>
    <p>{`So, what does Ethereum really `}<em parentName="p">{`mean`}</em>{`? Happily, the most accurate answers to this are to be found
on block explorers, not in short essays. However, we can make an attempt in natural language here
by considering our communal imagery - the eight-sided logo.`}</p>
    <p>{`The `}<Link to="https://en.wikipedia.org/wiki/Octahedron" mdxType="Link">{`octahedron`}</Link>{` is the third
of the `}<Link to="https://en.wikipedia.org/wiki/Platonic_solid" mdxType="Link">{`five solids`}</Link>{` postulated
by Plato to make up all the elements - earth, water, fire, air and `}<em parentName="p">{`aether`}</em>{`. While discussing air in the
`}<em parentName="p">{`Timaeus`}</em>{` - the element with which the octahedron is associated - Plato writes: "there is the most
translucent kind which is called by the name of aether (αἰθήρ)". The word αἰθήρ in Homeric Greek
means "pure, fresh air" or "clear sky". It is also often translated as "quintessence": the material
that fills the region of the universe above the terrestrial sphere. The `}<em parentName="p">{`aether`}</em>{` is the air of
another realm, which nevertheless interacts with our own by virtue of the meaning we enact in
our use of the four terrestrial elements.`}</p>
    <p>{`The octahedron is the middle solid, and therefore associated in more modern metaphysics with
the heart; love; compassion; forgiveness; and healing. It has 8 faces, similar in a sense to
the `}<Link to="https://en.wikipedia.org/wiki/Noble_Eightfold_Path" mdxType="Link">{`Noble Eightfold Path`}</Link>{`.
As is the case with all Platonic solids, it is symmetric and so its reflection remains the same.  `}</p>
    <p>{`Such shapes remind us that we are all just mirrors for each other.`}</p>
    <p>{`More practically, `}<Link to="https://en.wikipedia.org/wiki/Space_frame" mdxType="Link">{`space frames`}</Link>{` are a commonly used architectural design, extended into `}<Link to="https://tinyurl.com/mister-fuller" mdxType="Link">{`octagonal trusses by Buckminster Fuller`}</Link>{` in his work on geodesic domes. Fuller is often quoted in crypto circles, with this being the go-to choice
for many of his intellectual descendants:`}</p>
    <blockquote>
      <p parentName="blockquote">{`“You never change things by fighting the existing reality. To change something, build a new
model that makes the existing model obsolete.”`}</p>
    </blockquote>
    <h3 {...{
      "id": "listen",
      "style": {
        "position": "relative"
      }
    }}>{`Listen`}<a parentName="h3" {...{
        "href": "#listen",
        "aria-label": "listen permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <AudioPlayer src="https://kernel-audio.s3.amazonaws.com/+M1E1+-+Meaning.mp3" mdxType="AudioPlayer" />
    <h2 {...{
      "id": "further-references",
      "style": {
        "position": "relative"
      }
    }}>{`Further References`}<a parentName="h2" {...{
        "href": "#further-references",
        "aria-label": "further references permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p>{`"To know is to possess, and any fact is possessed by everyone who knows it, whereas those who `}<em parentName="p">{`feel`}</em>{` truth are possessed, not possessors."`}</p>
        <p><img parentName="p" {...{
            "src": "/images/ethereum.png",
            "alt": "Ethereum Logo"
          }}></img></p>
        <Link to="https://www.youtube.com/watch?v=kffo3pxNO7c" mdxType="Link">ee cummings</Link>
      </Box>
    </InfoBlock>
    <List mdxType="List">
      <Link to="https://www.youtube.com/watch?v=UlfotWZdvVk" mdxType="Link">
        <p>{`Once you have the meaning, let go of the words`}</p>
      </Link>
      <Link to="https://youtu.be/X_7U1ApSzaM?t=8103" mdxType="Link">
        <p>{`Meaning is inseparable from reality. Love is an existential stance; a commitment.`}</p>
      </Link>
      <Link to="https://www.youtube.com/watch?v=54l8_ewcOlY&list=PLND1JCRq8Vuh3f0P5qjrSdb5eC1ZfZwWJ" mdxType="Link">
        <p>{`Exaptation and psychotechnologies`}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      